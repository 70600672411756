// import React, { useState, useEffect, useRef } from 'react';
// import config from "../../config";
// import "./style.css";
// import { FaWindowClose } from "react-icons/fa";

// const GENDER_MAP = {
//   ML: "Male",
//   FL: "Female",
//   OT: "Other"
// };

// const Patientfile = ({ AppointmentId, PatientName, AppointmentStatus, user, PatientAge, PatientGender }) => {
//   const [pdfUrl, setPdfUrl] = useState('');
//   const [files, setFiles] = useState([]);
//   const fileInputRef = useRef(null);

//   const isDOBFormat = (dateString) => {
//     return !isNaN(Date.parse(dateString));
//   };

//   const getDisplayAge = (age) => {
//     if (!age) return 'Not specified';
  
//     const formatAge = (years, months) => {
//       years = parseInt(years, 10);
//       months = parseInt(months, 10);
//       const yearText = years === 1 ? 'year' : 'years';
//       const monthText = months === 1 ? 'month' : 'months';
//       return `${years} ${yearText} ${months} ${monthText}`;
//     };
  
//     // If age is in the format "22-6-12" (years-months-days)
//     if (typeof age === 'string' && age.includes('-')) {
//       const [years, months] = age.split('-');
//       return formatAge(years, months);
//     }
  
//     // Check if the input is a date (DOB) format
//     // if (isDOBFormat(age)) {
//     //   const today = new Date();
//     //   const [day, month, year] = age.split('-').map(Number);
//     //   const birthDate = new Date(year, month - 1, day);
  
//     //   let years = today.getFullYear() - birthDate.getFullYear();
//     //   let months = today.getMonth() - birthDate.getMonth();
  
//     //   if (today.getDate() < birthDate.getDate()) {
//     //     months -= 1;
//     //   }
  
//     //   if (months < 0) {
//     //     years -= 1;
//     //     months += 12;
//     //   }
  
//     //   return formatAge(years, months);
//     // }
  
//     // Check if the input is a fractional year (e.g., "12.7")
//     if (typeof age === 'string' || typeof age === 'number') {
//       console.log("if");
//       const ageNumber = parseFloat(age);
//       const years = Math.floor(ageNumber); // Integer part for years
//       const decimalPart = ageNumber - years; // Get the decimal part
//       const months = Math.floor(decimalPart * 12); // Convert the decimal part to months
  
//       return formatAge(years, months);
//     }
//     // Default case: return the age as is
//     console.log("else");
//     return age;
   
//   };
  
  
  
//   const displayAge = getDisplayAge(PatientAge);

//   useEffect(() => {
//     console.log("Patient Age Prop:", PatientAge);
//   }, [PatientAge]);
  

//   const displayGender = GENDER_MAP[PatientGender] || "Other";

//   useEffect(() => {
//     const fetchPdf = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}/api/v1/consultation/prescriptions/${AppointmentId}`);
//         if (response.ok) {
//           const blob = await response.blob();
//           const url = URL.createObjectURL(blob);
//           setPdfUrl(url);
//         } else {
//           setPdfUrl(null);
//         }
//       } catch (error) {
//         console.error('Error fetching PDF:', error);
//       }
//     };

//     fetchPdf();
//   }, [AppointmentId]);

//   const handleFileChange = (e) => {
//     setFiles(Array.from(e.target.files));
//   };

//   const handleRemoveFile = (index) => {
//     const newFiles = [...files];
//     newFiles.splice(index, 1);
//     setFiles(newFiles);
//   };

//   const handleSave = async () => {
//     if (files.length === 0) {
//       alert('Please select files to upload.');
//       return;
//     }

//     const formData = new FormData();
//     files.forEach(file => {
//       formData.append('files', file);
//     });
//     formData.append('appointment_id', AppointmentId);

//     try {
//       const response = await fetch(`${config.API_BASE_URL}/api/v1/consultation/prescriptions/upload`, {
//         method: 'POST',
//         headers: {
//           Authorization: `JWT ${user.authTokens?.access}`,
//         },
//         body: formData,
//       });

//       if (response.ok) {
//         setFiles([]);
//         const blob = await response.blob();
//         const url = URL.createObjectURL(blob);
//         setPdfUrl(url);
//         alert('Files uploaded successfully');
//       } else {
//         alert('File upload failed');
//       }
//     } catch (error) {
//       console.error('Error uploading files:', error);
//       alert('An error occurred while uploading the files');
//     }
//   };

//   return (
//     <>
//       <div className="patient-info">
//         <p>
//           Age: {displayAge} | 
//           Gender: {displayGender}
//         </p>
//       </div>
//       <div className="record">
//         <div className="selected-files">
//           {files.length > 0 ? (
//             files.map((file, index) => {
//               const truncatedName = file.name.length > 10 ? `${file.name.substring(0, 10)}...` : file.name;
//               return (
//                 <div key={index} className="file-item">
//                   <span>{truncatedName}</span>
//                   <FaWindowClose className='remove-btn' onClick={() => handleRemoveFile(index)} />
//                 </div>
//               );
//             })
//           ) : (
//             <div className="no-selected-files">
//               {/* No files selected */}
//             </div>
//           )}
//         </div>
//         {pdfUrl ? (
//           <div className="pdf-viewer">
//             <iframe src={pdfUrl} width="100%" height="500px" title="Patient File"></iframe>
//           </div>
//         ) : (
//           <div className="no-records">
//             No Records Found
//           </div>
//         )}

//         {user.is_doctor && AppointmentStatus !== 'CL' && (
//           <div className="upload-section">
//             <input
//               type="file"
//               multiple
//               hidden
//               onChange={handleFileChange}
//               ref={fileInputRef}
//             />
//             <button
//               onClick={() => fileInputRef.current.click()}
//               className="hoverbutton"
//             >
//               Choose File
//             </button>
//             <button onClick={handleSave} className="hoverbutton">Save</button>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Patientfile;


import React, { useState, useEffect, useRef } from 'react';
import config from "../../config";
import "./style.css";
import { FaWindowClose } from "react-icons/fa";

const GENDER_MAP = {
  ML: "Male",
  FL: "Female",
  OT: "Other"
};

const Patientfile = ({ AppointmentId, PatientName, AppointmentStatus, user, PatientAge, PatientGender }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  // Function to convert fractional age to years and months
  const getDisplayAge = (age) => {
    if (!age) return 'Not specified';
  
    const formatAge = (years, months) => {
      years = parseInt(years, 10);
      months = parseInt(months, 10);
      const yearText = years === 1 ? 'year' : 'years';
      const monthText = months === 1 ? 'month' : 'months';
  
      if (months > 0) {
        return `${years} ${yearText} ${months} ${monthText}`;
      } else {
        return `${years} ${yearText}`;
      }
    };
  
    // Handle age in the format "30-5-0" (years-months-days)
    if (typeof age === 'string' && age.includes('-')) {
      const [years, months] = age.split('-');
      return formatAge(years, months);
    }
  
    // Handle age as a float (e.g., 30.5)
    if (typeof age === 'number' || (typeof age === 'string' && !isNaN(parseFloat(age)))) {
      const ageStr = age.toString();
      let [years, months] = ageStr.split('.');
  
      // Handle cases where no decimal part exists
      months = months ? months.substring(0, 2) : '0';
      months = Math.round((parseInt(months) / Math.pow(10, months.length)) * 12);
  
      return formatAge(years, months);
    }
  
    // Default case: return the age as is
    return age;
  };
  

  const displayAge = getDisplayAge(PatientAge);

  useEffect(() => {
    console.log("Patient Age Prop:", PatientAge);
  }, [PatientAge]);

  const displayGender = GENDER_MAP[PatientGender] || "Other";

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/v1/consultation/prescriptions/${AppointmentId}`);
        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
        } else {
          setPdfUrl(null);
        }
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [AppointmentId]);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleSave = async () => {
    if (files.length === 0) {
      alert('Please select files to upload.');
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('appointment_id', AppointmentId);

    try {
      const response = await fetch(`${config.API_BASE_URL}/api/v1/consultation/prescriptions/upload`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${user.authTokens?.access}`,
        },
        body: formData,
      });

      if (response.ok) {
        setFiles([]);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        alert('Files uploaded successfully');
      } else {
        alert('File upload failed');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('An error occurred while uploading the files');
    }
  };

  return (
    <>
      <div className="patient-info">
        <p>
          Age: {displayAge} | 
          Gender: {displayGender}
        </p>
      </div>
      <div className="record">
        <div className="selected-files">
          {files.length > 0 ? (
            files.map((file, index) => {
              const truncatedName = file.name.length > 10 ? `${file.name.substring(0, 10)}...` : file.name;
              return (
                <div key={index} className="file-item">
                  <span>{truncatedName}</span>
                  <FaWindowClose className='remove-btn' onClick={() => handleRemoveFile(index)} />
                </div>
              );
            })
          ) : (
            <div className="no-selected-files">
              {/* No files selected */}
            </div>
          )}
        </div>
        {pdfUrl ? (
          <div className="pdf-viewer">
            <iframe src={pdfUrl} width="100%" height="500px" title="Patient File"></iframe>
          </div>
        ) : (
          <div className="no-records">
            No Records Found
          </div>
        )}

        {user.is_doctor && AppointmentStatus !== 'CL' && (
          <div className="upload-section">
            <input
              type="file"
              multiple
              hidden
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <button
              onClick={() => fileInputRef.current.click()}
              className="hoverbutton"
            >
              Choose File
            </button>
            <button onClick={handleSave} className="hoverbutton">Save</button>
          </div>
        )}
      </div>
    </>
  );
};

export default Patientfile;
