import React, { forwardRef, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { FiSend } from "react-icons/fi";
import GrowthChart from "./GrowthChart";
import useAxios from "../../utils/useAxios";
import axios from "axios";

import config from "../../config";
const baseURL = `${config.API_BASE_URL}`;

const swal = require("sweetalert2");

const ExpandableCard = forwardRef(({ content, setExpanded }, ref) => {
  const data = {
    options: {
      grid: {
        show: false,
      },
      // colors: ["#256e6b","#bff8e5","#52AA7D"],
      chart: {
        type: "line",
        stacked: false,
      },
      xaxis: {
        categories: [
          "2001",
          "2002",
          "2003",
          "2004",
          "2005",
          "2006",
          "2007",
          "2008",
          "2009",
          "2010",
          "2011",
        ],
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        width: [3],
        curve: "smooth",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "50%"
      //   }
      // },

      // fill: {
      //   opacity: [0.85, 0.25, 1],
      //   gradient: {
      //     inverseColors: false,
      //     shade: "light",
      //     type: "vertical",
      //     opacityFrom: 0.85,
      //     opacityTo: 0.55,
      //     stops: [0, 100, 100, 100]
      //   }
      // },
      markers: {
        size: 0,
      },
      // tooltip: {
      //   shared: true,
      //   intersect: false,
      //   y: {
      //     formatter: function(y) {
      //       if (typeof y !== "undefined") {
      //         return y.toFixed(1);
      //       }
      //       return y;
      //     }
      //   }
      // }
    },
    series: [
      // {
      //   name: "People Age(in years)",
      //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
      //   type: "column",
      //   color: "#256e6b",
      //   borderWidth: 0,
      // },
      // {
      //   name: "People Height(in cm)",
      //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
      //   type: "area",
      //   // color: '#bff8e5',
      // },
      {
        name: "People Weight(in kg)",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        color: "#52AA7D",
      },
    ],
  };

  return (
    <motion.div ref={ref} className="ExpandedCard" layoutId={content.id}>
      <motion.div className="card-title">
        <motion.h4>{content.heading}</motion.h4>
        <motion.div className="buttons">
          <motion.button className="btn">Add Data</motion.button>
          <motion.button className="btn">Edit</motion.button>
          <motion.button className="btn">Print</motion.button>
          <motion.button onClick={setExpanded} className="btn">
            Close
          </motion.button>
        </motion.div>
      </motion.div>
      {content.id === 1 ? (
        <>
          <motion.ul className="Chatbox">
            <motion.li style={{ paddingLeft: "0", fontSize: "1vw" }}>
              Please get your eyes dialated before appointment
            </motion.li>
          </motion.ul>
          <motion.form
            className="input-wrapper"
            style={{
              backgroundColor: "#ffffff",
              height: "15%",
              border: "1px solid #74C0C3",
              borderRadius: "10px",
            }}
          >
            <motion.input
              type="text"
              placeholder="Type something here..."
              id="message"
            />
            <motion.button
              type="submit"
              style={{ border: "none", background: "transparent" }}
            >
              <FiSend style={{ fontSize: "1.5vw", color: "#383c44" }} />
            </motion.button>
          </motion.form>
        </>
      ) : null}
      {content.id === 2 ? <GrowthChart state={data} /> : null}
      {content.id === 2 && <GrowthChart state={data} />}
      {content.id === 3 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
      {content.id === 4 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
      {content.id === 5 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
    </motion.div>
  );
});

export const CompactCard = ({ content, setExpanded, patient }) => {
  const axiosInstance = useAxios();

  const data = {
    options: {
      colors: ["#e9f6fa", "#256e6b", "#095D7E"],
      chart: {
        type: "line",
        stacked: false,
      },
      xaxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        axisBorder: {
          show: true,
          color: "#74C0C3",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      grid: {
        show: false,
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(1);
            }
            return y;
          },
        },
      },
    },
    series: [
      {
        name: "Age",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
        type: "area",
        color: "#256e6b",
        borderWidth: 0,
      },
      {
        name: "Height",
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
        type: "area",
        color: "#00e396",
      },
      {
        name: "Weight",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        color: "#52AA7D",
      },
    ],
  };
  const [height, setHeight] = useState("");
  const [heightUnit, setHeightUnit] = useState("cm");
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [bp, setBP] = useState("");
  const [bmi, setBMI] = useState("");
  const [isHeightDropdownOpen, setIsHeightDropdownOpen] = useState(false);
  const [isWeightDropdownOpen, setIsWeightDropdownOpen] = useState(false);

  const closeHeightDropdown = () => setIsHeightDropdownOpen(false);
  const closeWeightDropdown = () => setIsWeightDropdownOpen(false);


  // Load initial data for the patient if it exists
  // useEffect(() => {
  //   console.log(patientData);
  //   const existingPatient = patientData.find((p) => p.id === patient);
  //   if (existingPatient) {
  //     console.log(existingPatient);
  //     setHeight(existingPatient.height);
  //     setWeight(existingPatient.weight);
  //     setBP(existingPatient.bp);
  //     setBMI(existingPatient.bmi);
  //   } else {
  //     setHeight("");
  //     setWeight("");
  //     setBP("");
  //     setBMI("");
  //   }
  // }, [patient, patientData]);

  useEffect(() => {
    const fetchPatientVitals = async () => {
      console.log("fetching vitals for ", patient);
      try {
        const response = await axiosInstance.get(
          `api/v1/vitals/vitals/${patient}/`
        ); // Changed to GET request
        if (response.status === 200) {
          const data = response.data;
          console.log(data);
          setHeight(data.height || "");
          setWeight(data.weight || "");
          setBP(data.bp || "");
          setBMI(data.bmi || "");

         

        } else {
          console.error("Vitals not found");
          setHeight("");
          setWeight("");
          setBP("");
          setBMI("");
        }
      } catch (error) {
        console.error("Error fetching vitals", error);
        setHeight("");
          setWeight("");
          setBP("");
          setBMI("");
      }
    };

    fetchPatientVitals();
  }, [axiosInstance, patient]);

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };
  const toggleHeightDropdown = () =>
    setIsHeightDropdownOpen(!isHeightDropdownOpen);
  const toggleWeightDropdown = () =>
    setIsWeightDropdownOpen(!isWeightDropdownOpen);

  const handleHeightUnitSelect = (unit) => {
    if (unit !== heightUnit) {
      if (unit === "feet") {
        setHeight((prevHeight) => (prevHeight / 30.48).toFixed(2));
      } else {
        setHeight((prevHeight) => (prevHeight * 30.48).toFixed(2));
      }
      setHeightUnit(unit);
    }
    setIsHeightDropdownOpen(false);
  };

  const handleWeightUnitSelect = (unit) => {
    if (unit !== weightUnit) {
      if (unit === "gm") {
        setWeight((prevWeight) => (prevWeight * 1000).toFixed(2));
      } else {
        setWeight((prevWeight) => (prevWeight / 1000).toFixed(2));
      }
      setWeightUnit(unit);
    }
    setIsWeightDropdownOpen(false);
  };

  // const handleSubmit = () => {
  //   const newPatient = {
  //     id: patient,
  //     height: height,
  //     weight: weight,
  //     bp: bp,
  //     bmi: (weight / Math.pow(height / 100, 2)).toFixed(2),
  //   };

  //   const updatedPatientData = [
  //     ...patientData.filter((p) => p.id !== patient),
  //     newPatient,
  //   ];
  //   setPatientData(updatedPatientData);
  //   localStorage.setItem("patientData", JSON.stringify(updatedPatientData));
  //   console.log("updated vitals");
  //   console.log(localStorage.getItem("patientData"));
  //   swal.fire({
  //     title: "Vitals Updated",
  //     icon: "success",
  //     toast: true,
  //     timer: 6000,
  //     position: "top-right",
  //     timerProgressBar: true,
  //     showConfirmButton: false,
  //   });
  // };

  const handleSubmit = async () => {
    const newPatientVitals = {
      patient: patient, // patient ID
      height: heightUnit === "feet" ? (height * 30.48).toFixed(2) : height,
      weight: weightUnit === "gm" ? (weight / 1000).toFixed(2) : weight,
      bp: bp,
      bmi: ((weightUnit === "gm" ? (weight / 1000).toFixed(2) : weight) / Math.pow((heightUnit === "feet" ? (height * 30.48).toFixed(2) : height) / 100, 2)).toFixed(2),
    };
    setBMI(newPatientVitals.bmi);
    try {
      const response = await axiosInstance.post(
        "api/v1/vitals/vitals/",
        newPatientVitals
      );
      if (response.status === 201) {
        // Update the state with the new vitals
        setHeight(newPatientVitals.height);
        setWeight(newPatientVitals.weight);
        setBP(newPatientVitals.bp);
        setBMI(newPatientVitals.bmi);

      
        swal.fire({
          title: "Vitals Updated",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error submitting vitals", error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rows, setRows] = useState([{ col1: "", col2: "", col3: "" }]);
  const [patientReports, setPatientReports] = useState(() => {
    const data = localStorage.getItem("patientReports");
    return data ? JSON.parse(data) : {};
  });

  useEffect(() => {
    const existingReport = patientReports[patient] || {
      rows: [{ col1: "", col2: "", col3: "" }],
    };
    setRows(existingReport.rows);
  }, [patient, patientReports]);

  const handleOpenModal = () => {
    setRows(
      patientReports[patient]?.rows || [{ col1: "", col2: "", col3: "" }]
    );
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { col1: "", col2: "", col3: "" }]);
  };

  const handleSave = () => {
    const updatedReports = {
      ...patientReports,
      [patient]: { rows },
    };

    setPatientReports(updatedReports);
    localStorage.setItem("patientReports", JSON.stringify(updatedReports));
    swal.fire({
      title: "Report Saved",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
    handleCloseModal();
  };

  return (
    <motion.div
      className="CompactCard"
      layoutId={content.id}
      // onClick={setExpanded}
    >
      <motion.div className="heading">{content.heading}</motion.div>
      {content.id === 1 && (
        <motion.ul className="Chats">
          <motion.li style={{ paddingLeft: "0", marginTop: "15px" }}>
            Please get your eyes dialated before appointment
          </motion.li>
        </motion.ul>
      )}
      {content.id === 2 && <GrowthChart state={data} />}
      {content.id === 3 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "fit-content",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                margin: "10px 0",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 10px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                Height
              </label>
              <input
                type="number"
                placeholder="Height (in cm)"
                value={height}
                onChange={handleHeightChange}
                style={{ padding: "10px", flex: 1 }}
              />
              <div
                style={{ position: "relative", width: "20%" }}
                onMouseLeave={closeHeightDropdown}
              >
                <div
                  onClick={toggleHeightDropdown}
                 className="vitalselectedunit"
                >
                  {heightUnit}
                </div>
                {isHeightDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      // top: '100%',
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderTop: "none",
                      borderRadius: "9px",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      onClick={() => handleHeightUnitSelect("cm")}
                      style={{
                        borderRadius: "9px 9px 0 0",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      cm
                    </div>
                    <div
                      onClick={() => handleHeightUnitSelect("feet")}
                      style={{
                        borderRadius: "0 0 9px 9px",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      feet
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 9px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                Weight
              </label>
              <input
                type="number"
                placeholder="Weight (in kg)"
                value={weight}
                onChange={handleWeightChange}
                style={{ padding: "10px", flex: 1 }}
              />
              <div
                style={{ position: "relative", width: "20%" }}
                onMouseLeave={closeWeightDropdown}
              >
                <div
                  onClick={toggleWeightDropdown}
                   className="vitalselectedunit"
                >
                  {weightUnit}
                </div>
                {isWeightDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "9px",
                      borderTop: "none",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      onClick={() => handleWeightUnitSelect("kg")}
                      style={{
                        borderRadius: "9px 9px 0 0",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      kg
                    </div>
                    <div
                      onClick={() => handleWeightUnitSelect("gm")}
                      style={{
                        borderRadius: "0 0 9px 9px",
                      }}
                      className="vitaldropdown"
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#E0F2F1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "transparent")
                      }
                    >
                      gm
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0",
                padding: "0px",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 25px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                BP
              </label>
              <input
                type="text"
                placeholder="Blood Pressure (e.g., 120/80)"
                value={bp}
                onChange={(e) => setBP(e.target.value)}
                style={{
                  padding: "10px",
                  flex: 1,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                margin: "10px 0",
                padding: "0px",
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #0A9396",
                backgroundColor: "#fff",
              }}
            >
              <label
                style={{
                  padding: "8px 20px",
                  background: "#0A9396",
                  borderRadius: "9px",
                  color: "white",
                }}
              >
                BMI
              </label>
              <input
                type="text"
                placeholder="BMI"
                value={bmi}
                readOnly
                style={{
                  padding: "10px",
                  width: "100%",
                }}
              />
            </div>
            <button
              onClick={handleSubmit}
              style={{
                padding: "10px 20px",
                marginTop: "10px",
                borderRadius: "10px",
                border: "none",
                background: "#0A9396",
                color: "#fff",
                cursor: "pointer",
                width: "100%",
              }}
            >
              Submit
            </button>
          </div>
        </motion.div>
      )}
      {content.id === 4 && (
        <div>
          <motion.div
            style={{
              color: "#005F73",
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "500",
            }}
            onClick={handleOpenModal}
          >
            Report
          </motion.div>
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="d-flex justify-content-between m-1">
                  <h2>Report Form</h2>
                  <div
                    type="button"
                    onClick={handleCloseModal}
                    style={{ color: "red", fontSize: "20px" }}
                  >
                    Close
                  </div>
                </div>
                <div className="heading-report">
                  <th>Investigation Name</th>
                  <th>Value</th>
                  <th>Units</th>
                </div>
                <form>
                  {rows.map((row, index) => (
                    <div key={index} className="input-rows">
                      <input
                        type="text"
                        name="col1"
                        value={row.col1}
                        onChange={(e) => handleInputChange(index, e)}
                        className="input"
                      />
                      <input
                        type="number"
                        name="col2"
                        placeholder=""
                        value={row.col2}
                        onChange={(e) => handleInputChange(index, e)}
                        className="input"
                      />
                      <input
                        type="text"
                        className="input"
                        name="col3"
                        placeholder=""
                        value={row.col3}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  ))}
                  <div className="d-flex justify-content-between">
                    <div>
                      <button
                        type="button"
                        onClick={handleAddRow}
                        className="btns-report"
                      >
                        Add Row
                      </button>
                      <button
                        type="button"
                        onClick={handleSave}
                        className="btns-report"
                      >
                        Save
                      </button>
                    </div>
                    <div style={{ fontSize: "19px" }}>View All Reports</div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {content.id === 5 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Comming Soon</h5>
        </motion.div>
      )}
    </motion.div>
  );
};

const Card = ({ content, patient }) => {
  const [isExpanded, setExpanded] = useState(false);
  const cardRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return isExpanded ? (
    <ExpandableCard
      ref={cardRef}
      setExpanded={() => setExpanded(false)}
      content={content}
    />
  ) : (
    <CompactCard
      setExpanded={() => setExpanded(true)}
      content={content}
      patient={patient}
    />
  );
};

export default Card;
