// src/pages/ReceptionistHomePage.js
import React, { useEffect, useState, useRef } from 'react';
import ReceptionistSideBar from '../components/nav_sidebar/ReceptionistSideBar';
import ReceptionistHome from '../components/homepagecomponents/ReceptionistMainContent';
import '../components/homepagecomponents/style.css';
import ReceptionistNavBar from '../components/nav_sidebar/ReceptionistNavBar';
import useAxios from '../utils/useAxios';

export default function ReceptionistHomePage() {
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState([]);
  const axiosInstance = useAxios();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const sidebarRef = useRef(null);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('auth/users/me/');
        setUser(response.data);
        setUserName(response.data.full_name);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [axiosInstance]);

  return (
    <div className='grid-container'>
      <ReceptionistNavBar
        OpenSidebar={OpenSidebar} 
        openSidebarToggle={openSidebarToggle} 
        userName={userName} 
      />
      <ReceptionistSideBar 
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle} 
        OpenSidebar={OpenSidebar} 
      />
      <ReceptionistHome user={user} />  
    </div>
  );
}
