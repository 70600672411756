import React from 'react'
import './style.css'
import useAxios from '../utils/useAxios';
import { useState,useEffect,useRef } from 'react';
import ReceptionistNavBar from '../components/nav_sidebar/ReceptionistNavBar';
import ReceptionistSideBar from '../components/nav_sidebar/ReceptionistSideBar';
import { FcApproval } from "react-icons/fc";
import { MdBlock } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";

function ReceptionistAppointmentList() {
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState([]);
  const axiosInstance = useAxios();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const sidebarRef = useRef(null);
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('auth/users/me/');
        setUser(response.data);
        setUserName(response.data.full_name);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [axiosInstance]);
  return (
    <>
    <ReceptionistNavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <ReceptionistSideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      
    <div className='main-container'>
      <h3>Appointments List</h3>
      <p>Preview and Approve Online Appoinments</p>
      <div className=''>
    <div className='container-list'>
        <img className='check-up' src='./checkup.png' alt='checkup'/>

        <div className='separator'></div>

        <div className='text-container'>
        <h4>Appointment Id. -24</h4>
        <p>13 MAY,1:00PM</p>
        </div>
        <div className='separator'></div>
        <div className=''>
        <h4><FaRegCircleUser className='user-icon img-fluid' />John Doe</h4>
        <p>Age.-27,Sex-M</p>
        </div>

        <div className='separator'></div>
        <div className=''>
        <h4 ><FaCalendarAlt className='cal-icon img-fluid' />Time Slot</h4>
        <p>14 MAY 2024,10:00AM-11:00AM</p>
        </div>

        <h4 className='status'>Approved</h4>

        <div className='m-2'>
        <button className='btns btns-approve d-flex'>
        <FcApproval className='m-1'/>Approve
        </button>
        </div>
        
        <div className='m-2'>
        <button className='btns btns-reject d-flex'>
        <MdBlock  className='m-1'/>Reject
        </button>
        </div>

        <div className='m-2'>
        <button className='btns btns-reschedule d-flex' style={{color:"black"}}>
        <img src='time.png' alt='reschedule' style={{width:'15px' , height:'15px',marginTop:'4px',marginRight:'3px'}} className='img-fluid'/>Reschedule
        </button>
        </div>

    </div>

    </div>
    </div>
    </>
  )
}

export default ReceptionistAppointmentList


// import React, { useState, useEffect, useRef } from 'react';
// import './style.css';
// import useAxios from '../utils/useAxios';
// import ReceptionistNavBar from '../components/nav_sidebar/ReceptionistNavBar';
// import ReceptionistSideBar from '../components/nav_sidebar/ReceptionistSideBar';

// function ReceptionistAppointmentList() {
//   const [userName, setUserName] = useState('');
//   const [user, setUser] = useState([]);
//   const [appointments, setAppointments] = useState([]);
//   const axiosInstance = useAxios();
//   const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
//   const sidebarRef = useRef(null);
  
//   const OpenSidebar = () => {
//     setOpenSidebarToggle(!openSidebarToggle);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const userResponse = await axiosInstance.get('auth/users/me/');
//         setUser(userResponse.data);
//         setUserName(userResponse.data.full_name);
        
//         const appointmentsResponse = await axiosInstance.get('api/appointments/');
//         setAppointments(appointmentsResponse.data);
//       } catch (error) {
//         console.error('Failed to fetch data', error);
//       }
//     };

//     fetchData();

//     const handleClickOutside = (event) => {
//       if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//         setOpenSidebarToggle(false);
//       }
//     };

//     document.addEventListener('mousedown', handleClickOutside);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [axiosInstance]);

//   return (
//     <>
//       <ReceptionistNavBar
//         OpenSidebar={OpenSidebar}
//         openSidebarToggle={openSidebarToggle}
//         userName={userName}
//       />
//       <ReceptionistSideBar
//         ref={sidebarRef}
//         openSidebarToggle={openSidebarToggle}
//         OpenSidebar={OpenSidebar}
//       />
//       <div className='main-container'>
//         <h3>Appointments List</h3>
//         <p>Preview and Approve Online Appointments</p>
//         {appointments.length > 0 ? (
//           appointments.map((appointment) => (
//             <div className='container-list' key={appointment.id}>
//               <img className='check-up' src='./checkup.png' alt='checkup' />
//               <div className=''>
//                 <h4>Appointment Id. - {appointment.id}</h4>
//                 <p>{new Date(appointment.date).toLocaleDateString()}, {appointment.time}</p>
//               </div>
//               <div className=''>
//                 <h4>{appointment.patient_name}</h4>
//                 <p>Age - {appointment.patient_age}, Sex - {appointment.patient_sex}</p>
//               </div>
//               <div className=''>
//                 <h4>Time Slot</h4>
//                 <p>{new Date(appointment.timeslot_start).toLocaleDateString()}, {appointment.timeslot_start} - {appointment.timeslot_end}</p>
//               </div>
//               <h4>{appointment.status}</h4>
//               <div>
//                 <button className='btn'>
//                   Approve
//                 </button>
//               </div>
//               <div>
//                 <button className='btn'>
//                   Reject
//                 </button>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className='list-box'>
//           <h6>No appointments available</h6>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }

// export default ReceptionistAppointmentList;

