import React, { useState, useEffect, useRef, useContext } from 'react';
import { FaEnvelope, FaUserCircle, FaCamera } from 'react-icons/fa';
import { PiPhoneFill } from "react-icons/pi";
import './style.css';
import useAxios from '../utils/useAxios';
import ReceptionistNavBar from '../components/nav_sidebar/ReceptionistNavBar';
import ReceptionistSideBar from '../components/nav_sidebar/ReceptionistSideBar';
import AuthContext from '../context/AuthContext';

function ReceptionistProfile() {
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [preview, setPreview] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const axiosInstance = useAxios();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const sidebarRef = useRef(null);
  const fileInputRef = useRef(null);
  const { logoutUser } = useContext(AuthContext);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('auth/users/me/');
        setUser(response.data);
        setUserName(response.data.full_name);
        setProfilePic(response.data.profile_pic);
        setPreview(response.data.profile_pic);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [axiosInstance]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      setProfilePic(file);
    }
  };

  const handleUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input dialog
    }
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append('profile_pic', profilePic);

    try {
      await axiosInstance.post('auth/users/upload_profile_pic/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const response = await axiosInstance.get('auth/users/me/');
      setUser(response.data);
      setProfilePic(response.data.profile_pic);
      setPreview(response.data.profile_pic);
    } catch (error) {
      console.error('Failed to upload image', error);
    }
  };

  const handleRemoveProfilePic = async () => {
    try {
      await axiosInstance.post('auth/users/remove_profile_pic/');
      const response = await axiosInstance.get('auth/users/me/');
      setUser(response.data);
      setProfilePic(null);
      setPreview(''); // Clear the preview
    } catch (error) {
      console.error('Failed to remove image', error);
    }
  };

  return (
    <>
      <ReceptionistNavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <ReceptionistSideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <div className='container-profile'>
        <div
          className='profile-pic-container'
          onMouseEnter={() => setShowOptions(true)}
          onMouseLeave={() => setShowOptions(false)}
        >
          <input
            type='file'
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept='image/*'
            onChange={handleFileChange}
          />
          <label htmlFor='profilePicInput' className='profile-pic-label'>
            <FaCamera size={24} color='#fff' />
            <img
              src={preview || user.profile_pic || 'user.png'}
              alt='Profile'
              className='profile-pic'
            />
          </label>
          {showOptions && (
            <div className='profile-options'>
           
              <li className='btn-option' onClick={handleUpload}>Upload New</li>
             <li className='btn-option' onClick={handleRemoveProfilePic}>Remove</li>
            </div>
          )}
        </div>
        <h2>{userName}</h2>
        <FaEnvelope className='icon-msg' /> {user.email}
        <p>
          <PiPhoneFill className='icon-ph'/> +91-{user.phone_number}
        </p>
        <div className='link'>Forgot Password</div>
        <div className='link'>Change Password</div>
        <button className='btn-signout' onClick={logoutUser}>Sign Out</button>
      </div>
    </>
  );
}

export default ReceptionistProfile;
