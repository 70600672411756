import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { FiHelpCircle, FiHome } from "react-icons/fi";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { FaList } from "react-icons/fa6";
import { PiSignOutBold } from "react-icons/pi";
import img from '../images/receptionist.png';
import AuthContext from '../../context/AuthContext';
import { FaCaretDown } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { BsCalendarDate, BsPersonAdd } from "react-icons/bs"; // Add this line
import { MdManageAccounts } from "react-icons/md"; 

const ReceptionistSideBar = forwardRef(({ openSidebarToggle }, ref) => {
    const navigate = useNavigate();
    const {  authTokens } = useContext(AuthContext);
    const [user, setUser] = useState([]);

    useEffect(() => {
        fetch(`${config.API_BASE_URL}/auth/users/me/`,{ 
          method: "GET",
          headers: { "Authorization" : `JWT ${authTokens?.access}` }
        })
          .then((res) => res.json())
          .then((data) => {
            setUser(data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
    }, [authTokens]);

    return (
        <div ref={ref} id="sidebar" className={openSidebarToggle ? "sidebar-responsive": ""}>
            <div className='sidebar-title'>
                <div className='sidebar-brand'>
                    {
                        user.profile_pic !== null ? <img src={user.profile_pic} alt="Avatar"/> : <img src={img} alt="Avatar"/>
                    }
                    <div className="title"><>{user.full_name}</>
                        {/* <div style={{'fontSize': '1.2vw','fontWeight': '400','margin': '0vw'}}>Clinic name</div> */}
                    </div>
                </div>
            </div>
            <ul className='sidebar-list'>
                <li className='sidebar-list-item' onClick={() => navigate("/receptionisthome")}>
                    <FiHome className='icon'/> Home
                </li>
                <li className='sidebar-list-item' onClick={() => navigate("/receptionistbookappointment",{state:{ userName: user.full_name }})}>
                    <BsCalendarDate  className='icon'/> Book Appointment
                </li>
                <li className='sidebar-list-item' onClick={() => navigate("/receptionistappointmentrequest",{state:{ userName: user.full_name }})}>
                    <VscGitPullRequestGoToChanges  className='icon'/> Appointment Requests
                </li>
                <li className='sidebar-list-item' onClick={() => navigate("/rec/todopage",{state:{ userName: user.full_name }})}>
                    <FaList  className='icon'/> To-Do List
                </li>
                <li className='sidebar-list-item' onClick={() => navigate("/receptionistprofilepage",{state:{ userName: user.full_name , userPhoneNumber : user.phone_number }})}>
                    <MdManageAccounts  className='icon'/> Profile
                </li>
                <li className='sidebar-list-item'>
                    <FiHelpCircle className='icon'/> Help
                </li>
                
            </ul>
        </div>
    );
});

export default ReceptionistSideBar;
