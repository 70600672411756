/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import config from "../../config";
import useAxios from "../../utils/useAxios";

const ExpandableCard = forwardRef(({ content, setExpanded, data }, ref) => {
  return (
    <motion.div ref={ref} className="Expanded" layoutId={content.id}>
      <motion.div className="card-title">
        <motion.h4>{content.heading}</motion.h4>
        <motion.div className="buttons">
          <motion.button onClick={setExpanded} className="btn">
            Close
          </motion.button>
        </motion.div>
      </motion.div>
      <motion.div
        style={{
          color: "#005F73",
          width: "100%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data ? data : "Loading..."}
      </motion.div>
    </motion.div>
  );
});

const CompactCard = ({ content, setExpanded }) => {
  return (
    <motion.div
      layoutId={content.id}
      onClick={setExpanded}
      className="NavOptions"
    >
      {content.icon} {content.heading}
    </motion.div>
  );
};

const NavCard = ({ content }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [data, setData] = useState(null);
  const [records, setRecords] = useState([]);
  const [pdfBlobs, setPdfBlobs] = useState({});
  const [vitalsData, setVitalsData] = useState(null);
  const cardRef = useRef(null);
  const axiosInstance = useAxios();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isExpanded && content.renderFunction) {
      switch (content.renderFunction) {
        case "renderPrescription":
          fetchPrescriptionRecords();
          break;
        case "renderVitals": // New case for rendering vitals
        fetchVitalsData();
          break;
        default:
          break;
      }
    } else {
      setData(null); // Clear data when card is collapsed
      setVitalsData(null);
    }
  }, [isExpanded, content.renderFunction]);

  const fetchVitalsData = async () => {
    try {
      const response = await axiosInstance.get(`api/v1/vitals/vitals/${content.patientId}/`);
      if (response.status === 200) {
        setVitalsData(response.data);
      } else {
        console.error("Failed to fetch vitals data");
      }
    } catch (error) {
      console.error("Error fetching vitals data:", error);
    }
  };

  const renderVitals = () => {
    if (!vitalsData) {
      return <div>Loading vitals data...</div>;
    }

    return (
      <motion.div
        style={{
          color: "#005F73",
          width: "50%",
          height: "fit-content",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}
          >
            <label
              style={{
                padding: "8px 10px",
                background: "#0A9396",
                borderRadius: "9px",
                color: "white",
                width: "50%",
              }}
            >
              Height
            </label>
            <div
              style={{ padding: "10px", textAlign: 'center', flex: 1 }}
            >
              {vitalsData.height} cm
            </div>
          </div>

          <div
            style={{
              display: "flex",
              margin: "10px 0",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}
          >
            <label
              style={{
                padding: "8px 9px",
                background: "#0A9396",
                borderRadius: "9px",
                color: "white",
                width: "50%",
              }}
            >
              Weight
            </label>
            <div
              style={{ padding: "10px", textAlign: 'center', flex: 1 }}
            >
              {vitalsData.weight} kg
            </div>
          </div>

          <div
            style={{
              display: "flex",
              margin: "10px 0",
              padding: "0px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}
          >
            <label
              style={{
                padding: "8px 25px",
                background: "#0A9396",
                borderRadius: "9px",
                color: "white",
                width: "50%",
              }}
            >
              BP
            </label>
            <div
              style={{
                padding: "10px", textAlign: 'center',
                flex: 1,
              }}
            >
              {vitalsData.bp} mmHg
            </div>
          </div>

          <div
            style={{
              display: "flex",
              margin: "10px 0",
              padding: "0px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}
          >
            <label
              style={{
                padding: "8px 25px",
                background: "#0A9396",
                borderRadius: "9px",
                color: "white",
                width: "50%",
              }}
            >
              BMI
            </label>
            <div
              style={{
                padding: "10px", textAlign: 'center',
                flex: 1,
              }}
            >
              {vitalsData.bmi}
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  const fetchPrescriptionRecords = async () => {
    try {
      const response = await axiosInstance.get(
        content.apiEndpoint + content.AppointmentId + "/"
      );
      if (response.status === 200) {
        setRecords(response.data);

        // Fetch PDFs and create blob URLs
        const pdfPromises = response.data
          .filter((record) => record.file.endsWith(".pdf"))
          .map(async (record) => {
            try {
              const fileName = record.file.split("/").pop();
              console.log(
                `/api/v1/patient-records/record/${content.AppointmentId}/${fileName}/`
              );
              const fileResponse = await axiosInstance.get(
                `/api/v1/patient-records/record/${content.AppointmentId}/${fileName}/`,
                {
                  responseType: "blob", // Handling response as a blob
                }
              );
              if (fileResponse.status === 200) {
                const url = URL.createObjectURL(fileResponse.data);

                console.log(url);
                setPdfBlobs((prev) => ({ ...prev, [fileName]: url }));
              } else {
                console.error(`Failed to fetch PDF: ${fileName}`);
              }
            } catch (error) {
              console.error(`Error fetching PDF`, error);
            }
          });

        await Promise.all(pdfPromises);
      } else {
        console.error("Failed to fetch records");
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  const renderPrescription = () =>
    records.length > 0 ? (
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          width: "100%",
          height: "100%",
          gap: "1rem", // Adding space between items
          padding: "1rem", // Adding padding to ensure the items are not sticking to the edges
        }}
      >
        {records.map((record, index) => {
          const fileName = record.file.split("/").pop();
          return (
            <div
              key={index}
              style={{
                width: "90%",
                height: "100%",
                scrollSnapAlign: "center", // Ensures items snap to the center
                flexShrink: 0, // Prevents items from shrinking when the container is smaller
              }}
            >
              {record.file.endsWith(".pdf") ? (
                <embed
                  src={pdfBlobs[fileName] || ""}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  className="rounded-3xl"
                />
              ) : (
                <img
                  src={`${config.API_BASE_URL}/api/v1/patient-records/record/${content.AppointmentId}/${fileName}`}
                  alt="Patient Record"
                  className="rounded-3xl"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </div>
          );
        })}
      </div>
    ) : (
      <div className="record">
        <div style={{ fontSize: "1.3vw", textAlign: "center" }}>
          No Records Found
        </div>
      </div>
    );

  return isExpanded ? (
    <ExpandableCard
      ref={cardRef}
      setExpanded={() => setExpanded(false)}
      content={content}
      data={
        content.renderFunction === "renderPrescription"
          ? renderPrescription()
          : content.renderFunction === "renderVitals"
          ? renderVitals()
          : data
      }
    />
  ) : (
    <CompactCard setExpanded={() => setExpanded(true)} content={content} />
  );
};

export default NavCard;
