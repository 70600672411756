import React, { useState, useEffect, useRef } from 'react';
import Calendar from './Calendar';
import TodoForm from './TodoForm';
import './style.css';
// import ReceptionistNavBar from '../components/nav_sidebar/ReceptionistNavBar';
// import ReceptionistSideBar from '../components/nav_sidebar/ReceptionistSideBar';
import useAxios from '../utils/useAxios';
import SideBar from '../components/nav_sidebar/SideBar';
import NavBar from '../components/nav_sidebar/NavBar';

const Todopage = () => {
  const [tasks, setTasks] = useState([]);
  const [view, setView] = useState('day');
  const [selectedTask, setSelectedTask] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [userName, setUserName] = useState('');
  const [user, setUser] = useState([]);
  const axiosInstance = useAxios();
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const sidebarRef = useRef(null);
  const getFormattedDate = (date = new Date()) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axiosInstance.get('api/v1/tasks/all/', {
          params: { date: getFormattedDate()},
        });
        console.log(response.data)
        setTasks(response.data);
      } catch (error) {
        console.error('Failed to fetch tasks', error);
      }
    };
  
    fetchTasks();
  }, [axiosInstance]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('auth/users/me/');
        setUser(response.data);
        setUserName(response.data.full_name);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebarToggle(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [axiosInstance]);

  // const addTask = (task) => {
  //   console.log(task)
  //   if (selectedTask) {
  //     // Edit existing task
  //     setTasks((prevTasks) =>
  //       prevTasks.map((t) => (t.id === task.id ? task : t))
  //     );
  //   } else {
  //     // Add new task
  //     setTasks((prevTasks) => [...prevTasks, task]);
  //   }
  //   setShowForm(false);
  //   setSelectedTask(null);
  // };

  const addTask = async (task) => {
    try {
      // Ensure 'user' is not included in the task object sent to the backend
      const { user, ...taskData } = task;
      const response = await axiosInstance.post('api/v1/tasks/create/', taskData);
      console.log(response.data);
      if (response.status === 201) {
        setTasks([...tasks, response.data]);
      }
    } catch (error) {
      console.error('Failed to add task', error);
    }
  };
  
  const deleteTask = async (taskId) => {
    try {
      await axiosInstance.delete(`api/v1/tasks/delete/${taskId}/`);
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
    } catch (error) {
      console.error('Failed to delete task', error);
    }
  };
  

  const handleSlotClick = (task) => {
    setSelectedTask(task || null);
    setShowForm(true);
  };

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <div className="todo">
      <NavBar
        OpenSidebar={OpenSidebar}
        openSidebarToggle={openSidebarToggle}
        userName={userName}
      />
      <SideBar
        ref={sidebarRef}
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <div className="">
        <Calendar tasks={tasks} view={view} viewChange={handleViewChange} onSlotClick={handleSlotClick} />
        {showForm && (
          <div className="form-modal">
            <TodoForm addTask={addTask} task={selectedTask} deleteTask={deleteTask} closeForm={() => setShowForm(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Todopage;
