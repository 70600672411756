import React, { useState, useEffect,forwardRef,useRef } from "react";
import { motion } from "framer-motion";
import { FiSend } from "react-icons/fi";
import GrowthChart from "./GrowthChart";
const swal = require("sweetalert2");

export const CompactCard = ({ content, setExpanded, patient }) => {
  const data = {
    options: {
      colors: ["#e9f6fa", "#256e6b", "#095D7E"],
      chart: {
        type: "line",
        stacked: false,
      },
      xaxis: {
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        axisBorder: {
          show: true,
          color: "#74C0C3",
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(1);
            }
            return y;
          },
        },
      },
    },
    series: [
      {
        name: "Age",
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
        type: "area",
        color: "#256e6b",
        borderWidth: 0,
      },
      {
        name: "Height",
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
        type: "area",
        color: "#00e396",
      },
      {
        name: "Weight",
        type: "line",
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        color: "#52AA7D",
      },
    ],
  };

  const [height, setHeight] = useState("");
  const [heightUnit, setHeightUnit] = useState("cm");
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [bp, setBP] = useState("");
  const [bmi, setBMI] = useState("");
  const [isHeightDropdownOpen, setIsHeightDropdownOpen] = useState(false);
  const [isWeightDropdownOpen, setIsWeightDropdownOpen] = useState(false);

  const closeHeightDropdown = () => setIsHeightDropdownOpen(false);
  const closeWeightDropdown = () => setIsWeightDropdownOpen(false);


  const [patientData, setPatientData] = useState(() => {
    const data = localStorage.getItem("patientData");
    return data ? JSON.parse(data) : [];
  });

  useEffect(() => {
    const existingPatient = patientData.find((p) => p.id === patient);
    if (existingPatient) {
      setHeight(existingPatient.height);
      setWeight(existingPatient.weight);
      setBP(existingPatient.bp);
      setBMI(existingPatient.bmi);
    } else {
      setHeight("");
      setWeight("");
      setBP("");
      setBMI("");
    }
  }, [patient, patientData]);

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const toggleHeightDropdown = () => setIsHeightDropdownOpen(!isHeightDropdownOpen);
  const toggleWeightDropdown = () => setIsWeightDropdownOpen(!isWeightDropdownOpen);

  const handleHeightUnitSelect = (unit) => {
    if (unit !== heightUnit) {
      if (unit === "feet") {
        setHeight((prevHeight) => (prevHeight / 30.48).toFixed(2));
      } else {
        setHeight((prevHeight) => (prevHeight * 30.48).toFixed(2));
      }
      setHeightUnit(unit);
    }
    setIsHeightDropdownOpen(false);
  };

  const handleWeightUnitSelect = (unit) => {
    if (unit !== weightUnit) {
      if (unit === "gm") {
        setWeight((prevWeight) => (prevWeight * 1000).toFixed(2));
      } else {
        setWeight((prevWeight) => (prevWeight / 1000).toFixed(2));
      }
      setWeightUnit(unit);
    }
    setIsWeightDropdownOpen(false);
  };

  const handleSubmit = () => {
    const newPatient = {
      id: patient,
      height: height,
      weight: weight,
      bp: bp,
      bmi: (weight / Math.pow(height / 100, 2)).toFixed(2),
    };

    const updatedPatientData = [
      ...patientData.filter((p) => p.id !== patient),
      newPatient,
    ];
    setPatientData(updatedPatientData);
    localStorage.setItem("patientData", JSON.stringify(updatedPatientData));
    console.log("updated vitals");
    console.log(localStorage.getItem("patientData"));
    swal.fire({
      title: "Vitals Updated",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rows, setRows] = useState([{ col1: "", col2: "", col3: "" }]);
  const [patientReports, setPatientReports] = useState(() => {
    const data = localStorage.getItem("patientReports");
    return data ? JSON.parse(data) : {};
  });

  useEffect(() => {
    const existingReport = patientReports[patient] || { rows: [{ col1: "", col2: "", col3: "" }] };
    setRows(existingReport.rows);
  }, [patient, patientReports]);

  const handleOpenModal = () => {
    setRows(patientReports[patient]?.rows || [{ col1: "", col2: "", col3: "" }]);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { col1: "", col2: "", col3: "" }]);
  };

  const handleSave = () => {
    const updatedReports = {
      ...patientReports,
      [patient]: { rows },
    };

    setPatientReports(updatedReports);
    localStorage.setItem("patientReports", JSON.stringify(updatedReports));
    swal.fire({
      title: "Report Saved",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
    handleCloseModal();
  };

  return (
    <motion.div className="CompactCard" layoutId={content.id}>
      <motion.div className="heading">{content.heading}</motion.div>
      {content.id === 1 && (
        <motion.ul className="Chats">
          <motion.li style={{ marginTop: "15px" }}>
            Please get your eyes dialated before appointment 
          </motion.li>
        </motion.ul>
      )}
      {/* {content.id === 2 && <GrowthChart state={data} />} */}
      {content.id === 3 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "fit-content",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div style={{
              display: "flex",
              margin: "10px 0",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}>
              <label style={{padding:"8px 10px", background:"#0A9396", borderRadius:"9px", color:"white"}}>Height</label>
              <input
                type="number"
                placeholder="Height (in cm)"
                value={height}
                onChange={handleHeightChange}
                style={{ padding: "10px", flex: 1 }}
              />
              <div style={{ position: 'relative', width: '20%'}} onMouseLeave={closeHeightDropdown}>
                <div 
                  onClick={toggleHeightDropdown}
                  style={{
                     height:"42px",
                     padding:"10px",
                    cursor: 'pointer',
                    border: '1px solid #0A9396',
                    borderRadius: ' 9px',
                    fontSize:"1vw",
                    fontWeight:"600",
                  }}
                >
                  {heightUnit}
                </div>
                {isHeightDropdownOpen && (
                  <div style={{
                    position: 'absolute',
                    // top: '100%',
                    left: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderTop: 'none',
                    borderRadius:"9px",
                    zIndex: 1000
                  }}>
                    <div onClick={() => handleHeightUnitSelect('cm')} style={{ padding: '5px',textAlign:"center", cursor: 'pointer',fontSize:"1vw",fontWeight:"600",borderRadius:"9px 9px 0 0" }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#E0F2F1"}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}>cm</div>
                    <div onClick={() => handleHeightUnitSelect('feet')} style={{ padding: '5px',textAlign:"center", cursor: 'pointer',fontWeight:"600",fontSize:"1vw",borderRadius:"0 0 9px 9px" }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#E0F2F1"}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}>feet</div>
                  </div>
                )}
              </div>
            </div>

            <div style={{
              display: "flex",
              margin: "10px 0",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
             
            }}>
              <label style={{padding:"8px 9px", background:"#0A9396", borderRadius:"9px", color:"white"}}>Weight</label>
              <input
                type="number"
                placeholder="Weight (in kg)"
                value={weight}
                onChange={handleWeightChange}
                style={{ padding: "10px", flex: 1 }}
              />
              <div style={{ position: 'relative', width: '20%' }}  onMouseLeave={closeWeightDropdown}>
                <div 
                  onClick={toggleWeightDropdown}
                  
                  style={{
                    height:"42px",
                    padding: '10px',
                    cursor: 'pointer',
                    border: '1px solid #0A9396',
                    borderRadius: ' 9px',
                    fontSize:"1vw",
                    fontWeight:"600",
                  }}
                >
                  {weightUnit}
                </div>
                {isWeightDropdownOpen && (
                  <div style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderRadius:"9px",
                    borderTop: 'none',
                    zIndex: 1000
                  }}>
                    <div onClick={() => handleWeightUnitSelect('kg')} style={{ padding: '5px',textAlign:"center", cursor: 'pointer' ,fontWeight:"600",fontSize:"1vw",borderRadius:"9px 9px 0 0"}} 
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#E0F2F1"}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}>
                      kg</div>
                    <div onClick={() => handleWeightUnitSelect('gm')} style={{ padding: '5px', textAlign:"center",cursor: 'pointer' ,fontWeight:"600",fontSize:"1vw",borderRadius:"0 0 9px 9px"}}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#E0F2F1"}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}>gm</div>
                  </div>
                )}
              </div>
            </div>

            <div style={{
              display:"flex",
              margin: "10px 0",
              padding: "0px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}>
              <label style={{padding:"8px 25px", background:"#0A9396", borderRadius:"9px", color:"white"}}>BP</label>
              <input
                type="text"
                placeholder="Blood Pressure (e.g., 120/80)"
                value={bp}
                onChange={(e) => setBP(e.target.value)}
                style={{
                  padding: "10px",
                  flex: 1
                }}
              />
            </div>

            <div style={{
              display:"flex",
              margin: "10px 0",
              padding: "0px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #0A9396",
              backgroundColor: "#fff",
            }}>
              <label style={{padding:"8px 20px", background:"#0A9396", borderRadius:"9px", color:"white"}}>BMI</label>
              <input
                type="text"
                placeholder="BMI"
                value={bmi}
                readOnly
                style={{
                  padding: "10px",
                  width: "100%",
                }}
              />
            </div>
            <button
              onClick={handleSubmit}
              style={{
                padding: "10px 20px",
                marginTop: "10px",
                borderRadius: "10px",
                border: "none",
                background:"#0A9396",
                color: "#fff",
                cursor: "pointer",
                width:'100%'
              }}
            >
              Submit
            </button>
          </div>
        </motion.div>
      )}
      {content.id === 4 && (
        <div>
          <motion.div
            style={{
              color: "#005F73",
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize:"20px",
              fontWeight:"500"
            }}
            onClick={handleOpenModal}
          >
            Report
          </motion.div>
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="d-flex justify-content-between m-1">
                  <h2>Report Form</h2>
                  <div type="button" onClick={handleCloseModal} style={{color:"red",fontSize:"20px" }}>
                    Close
                  </div>
                </div>  
                <div className="heading-report">
                  <th>Investigation Name</th>
                  <th>Value</th>
                  <th>Units</th>
                </div>
                <form>
                  {rows.map((row, index) => (
                    <div key={index} className="input-rows">
                      <input
                        type="text"
                        name="col1"
                        value={row.col1}
                        onChange={(e) => handleInputChange(index, e)}
                        className="input"
                      />
                      <input
                        type="number"
                        name="col2"
                        placeholder=""
                        value={row.col2}
                        onChange={(e) => handleInputChange(index, e)}
                        className="input"
                      />
                      <input
                        type="text"
                        className="input"
                        name="col3"
                        placeholder=""
                        value={row.col3}
                        onChange={(e) => handleInputChange(index, e)}
                      />
                    </div>
                  ))}
                  <div className="d-flex justify-content-between">
                    <div>
                      <button type="button" onClick={handleAddRow} className="btns-report">
                        Add Row
                      </button>
                      <button type="button" onClick={handleSave} className="btns-report">
                        Save
                      </button>
                    </div>
                    <div style={{fontSize:"19px"}}>View All Reports</div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
      {content.id === 5 && (
        <motion.div
          style={{
            color: "#005F73",
            width: "100%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5>Coming Soon</h5>
        </motion.div>
      )}
    </motion.div>
  );
};

export default CompactCard;
