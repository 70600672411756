// import { createContext, useState, useEffect } from "react";
// import { jwtDecode } from "jwt-decode";
// import { useNavigate } from "react-router-dom";
// import config from "../config";
// const swal = require("sweetalert2");

// const AuthContext = createContext();

// export default AuthContext;

// export const AuthProvider = ({ children }) => {
//   const [authTokens, setAuthTokens] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? JSON.parse(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [user, setUser] = useState(() =>
//     localStorage.getItem("authTokens")
//       ? jwtDecode(localStorage.getItem("authTokens"))
//       : null
//   );

//   const [loading, setLoading] = useState(true);

//   const Navigate = useNavigate();

//   const loginUser = async (phone_number, password) => {
//     try {
//       const response = await fetch(`${config.API_BASE_URL}/auth/jwt/create/`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           phone_number,
//           password,
//         }),
//       });
//       const data = await response.json();

//       if (response.status === 200) {
//         setAuthTokens(data);
//         setUser(jwtDecode(data.access));
//         localStorage.setItem("authTokens", JSON.stringify(data));
//         Navigate("/doc/home");
//         swal.fire({
//           title: "Login Successful",
//           icon: "success",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       } else {
//         swal.fire({
//           title: "Phone Number or Password is incorrect",
//           icon: "error",
//           toast: true,
//           timer: 8000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } catch (error) {
//       swal.fire({
//         title:
//           "Server did not respond properly, please try again after some time",
//         icon: "error",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     }
//   };

//   const registerUser = async (
//     phone_number,
//     password,
//     is_doctor,
//     first_name,
//     last_name,
//     email,
//     specialization_name
//   ) => {
//     const response = await fetch(`${config.API_BASE_URL}/auth/users/`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body:
//         JSON.stringify({
//           phone_number: phone_number,
//           first_name: first_name,
//           last_name: last_name,
//           password: password,
//           is_doctor: is_doctor,
//           specialization_name: specialization_name,
//         }),
//     });
//     const data = await response.json();
//     if (response.status === 201) {
//       Navigate("/doc/login");
//       swal.fire({
//         title: "Registration Successful, Login Now",
//         icon: "success",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     } else if (!response.ok) {
//       if (data.phone_number && data.phone_number.length > 0) {
//         swal.fire({
//           title: "Registration Error",
//           text: data.phone_number[0] + " Login Instead",
//           icon: "error",
//           toast: true,
//           timer: 6000,
//           position: "top-right",
//           timerProgressBar: true,
//           showConfirmButton: false,
//         });
//       }
//     } else {
//       swal.fire({
//         title: "An Error Occured " + response.status,
//         icon: "error",
//         toast: true,
//         timer: 6000,
//         position: "top-right",
//         timerProgressBar: true,
//         showConfirmButton: false,
//       });
//     }
//   };

//   const logoutUser = () => {
//     setAuthTokens(null);
//     setUser(null);
//     localStorage.removeItem("authTokens");
//     Navigate("/doc/login");
//     swal.fire({
//       title: "You have been logged out...",
//       icon: "success",
//       toast: true,
//       timer: 6000,
//       position: "top-right",
//       timerProgressBar: true,
//       showConfirmButton: false,
//     });
//   };

//   const contextData = {
//     user,
//     setUser,
//     authTokens,
//     setAuthTokens,
//     registerUser,
//     loginUser,
//     logoutUser,
//   };

//   useEffect(() => {
//     if (authTokens) {
//       setUser(jwtDecode(authTokens.access));
//     }
//     setLoading(false);
//   }, [authTokens, loading]);

//   return (
//     <AuthContext.Provider value={contextData}>
//       {loading ? null : children}
//     </AuthContext.Provider>
//   );
// };

import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import config from "../config";
const swal = require("sweetalert2");

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );

  const [loading, setLoading] = useState(true);

  const Navigate = useNavigate();

  const loginUser = async (phone_number, password) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/auth/jwt/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone_number,
          password,
        }),
      });
      const data = await response.json();

      if (response.status === 200) {
        setAuthTokens(data);
        const decodedToken = jwtDecode(data.access);
        setUser(decodedToken);
        localStorage.setItem("authTokens", JSON.stringify(data));

        // Navigate("/doc/home");
        console.log("Decoded Token:", decodedToken);
        console.log("Is Doctor:", decodedToken.is_doctor);

        if (decodedToken.user_id) {
          Navigate("/doc/home");
        } else {
          Navigate("/doc/ReceptionistHomePage");
        }

        swal.fire({
          title: "Login Successful",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        swal.fire({
          title: "Phone Number or Password is incorrect",
          icon: "error",
          toast: true,
          timer: 8000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      swal.fire({
        title:
          "Server did not respond properly, please try again after some time",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const registerUser = async (
    first_name,
    last_name,
    phone_number,
    email,
    password,
    is_doctor,
    is_receptionist,
    specialization_name
  ) => {
    console.log({
      phone_number,
      password,
      is_receptionist,
      is_doctor,
      first_name,
      last_name,
      email,
      specialization_name,
    });
    const response = await fetch(`${config.API_BASE_URL}/custom-signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phone_number,
        first_name: first_name,
        last_name: last_name,
        password: password,
        is_doctor: is_doctor,
        is_receptionist: is_receptionist,
        specialization_name: specialization_name,
      }),
    });
    const data = await response.json();
    if (response.status === 201 || response.status === 200) {
      // Success (either newly created or password set)
      Navigate("/doc/login");
      swal.fire({
        title: "Registration Successful. Login Now",
        icon: "success",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else if (response.status === 409) {
      swal.fire({
        title: "User Already Exists",
        text: "Login Instead",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } else if (!response.ok) {
      swal.fire({
        title: "An Error Occurred",
        text: data.detail || "Please try again later.",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    Navigate("/doc/login");
    swal.fire({
      title: "You have been logged out...",
      icon: "success",
      toast: true,
      timer: 6000,
      position: "top-right",
      timerProgressBar: true,
      showConfirmButton: false,
    });
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
