import React, { useContext, useState } from "react";
import QueueList from "./QueueList";

import Card from "./ReceptionistCard";
import config from "../../config";
import AuthContext from "../../context/AuthContext";
import Patientfile from "./Patientfile";
import swal from "sweetalert2";
import Modal from "react-modal";
import { FcNext, FcPrevious } from "react-icons/fc";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  subMonths,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay
} from "date-fns";
import './style.css';

function ReceptionistHome({ user }) {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("morning");
  const [currentMonth, setCurrentMonth] = useState(new Date()); 
  const { authTokens } = useContext(AuthContext);

  const cards = [
    {
      id: 1,
      heading: "Send message to patient",
    },
    {
      id: 3,
      heading: "Vitals",
    },
    {
      id: 4
    },
    {
      id: 5,
      heading: "Billing",
    },
  ];

  const handleSelectedItem = (selectedItem) => {
    setSelectedPatient({
      id: selectedItem.id,
      name: selectedItem.patient_name,
      status: selectedItem.status,
      age: selectedItem.patient_age ,
      gender: selectedItem.gender,
      patientId: selectedItem.patient_id,
    });
  };

  const handleReschedule = () => {
    setIsModalOpen(true);
  };

  console.log({selectedPatient});

  const handleDelete = () => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Replace with actual delete logic
          swal.fire("Deleted!", "The patient has been deleted.", "success");
          setSelectedPatient(null);
        }
      });
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleRescheduleSubmit = (e) => {
    e.preventDefault();
    // Handle the rescheduling logic here
    setIsModalOpen(false);
    swal.fire("Rescheduled!", "The appointment has been rescheduled.", "success");
  };

  const handleTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot); 
  };

  // Calendar functions and rendering
  const renderDaysOfWeek = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days.map((day, index) => <div key={index}>{day}</div>);
  };

  const renderCalendarDays = () => {
    const startDate = startOfMonth(currentMonth);
    const endDate = endOfMonth(currentMonth);
    const startWeek = startOfWeek(startDate);
    const endWeek = endOfWeek(endDate);
    const days = eachDayOfInterval({ start: startWeek, end: endWeek });

    return days.map((day, index) => (
      <div
        key={index}
        onClick={() => setSelectedDate(day)}
        className={`day ${
          isSameMonth(day, currentMonth) ? "" : "other-month"
        } ${isSameDay(day, selectedDate) ? "selected-day" : ""}`}
      >
        {format(day, "d")}
      </div>
    ));
  };

  return (
    <>
      <div className="main-container1">
        <QueueList onItemSelected={handleSelectedItem} />
      </div>
      {selectedPatient === null ? (
        <div className="noselected"></div>
      ) : (
        <>
          <div className="main-container2">
            <Patientfile
              AppointmentId={selectedPatient.id}
              PatientName={selectedPatient.name}
              AppointmentStatus={selectedPatient.status}
              user={user}
              PatientAge={selectedPatient.age}
              PatientGender={selectedPatient.gender}
            />
          </div>
          <div className="main-container3">
            <div style={{display:"flex",padding:"10px 8% 10px 10px",gap:"10px"}}>
              <button onClick={handleReschedule} className="recep-btn">
                Reschedule
              </button>
              <button onClick={handleDelete} className="recep-btn">Delete</button>
              </div>
            <div className="blocks">
              {cards.map((item) => (
                <Card key={item.id} content={item} patient={selectedPatient.id} />
              ))}
         
            </div>
          </div>
        </>
      )}

      <Modal isOpen={isModalOpen} onRequestClose={handleModalClose} className="ReactModal__Content">
        <h2>Reschedule Appointment</h2>
        <form onSubmit={handleRescheduleSubmit}>
          <div>
            <label>Select Date:</label>
            <div className="calendar-container">
              <div className="date-picker">
                <div className="month-year">
                  
                    
                    <FcPrevious onClick={() => setCurrentMonth((prev) => subMonths(prev, 1))}
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                 />
                
                  <div className="current-month">
                    {format(currentMonth, "MMMM yyyy")}
                  </div>
        
                    <FcNext  onClick={() => setCurrentMonth((prev) => addMonths(prev, 1))}
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}/>
               
                </div>
                <div className="days-of-week ">{renderDaysOfWeek()}</div>
                <div className="calendar-days mx-2">{renderCalendarDays()}</div>
              </div>
            </div>
          </div>
          <div>
            <label>Select Session :</label>
            <div className="time-slot-buttons">
              <button
                type="button"
                className={selectedTimeSlot === "morning" ? "active" : ""}
                onClick={() => handleTimeSlotChange("morning")}
              >
                Morning
              </button>
              <button
                type="button"
                className={selectedTimeSlot === "afternoon" ? "active" : ""}
                onClick={() => handleTimeSlotChange("afternoon")}
              >
                Afternoon
              </button>
              <button
                type="button"
                className={selectedTimeSlot === "evening" ? "active" : ""}
                onClick={() => handleTimeSlotChange("evening")}
              >
                Evening
              </button>
            </div>
          </div>
          <button type="submit">Submit</button>
          <button type="button" onClick={handleModalClose}>
            Cancel
          </button>
        </form>
      </Modal>
    </>
  );
}

export default ReceptionistHome;
