// import React, { useState } from 'react';
// import { FiChevronDown, FiChevronUp, FiChevronLeft, FiChevronRight } from "react-icons/fi";

// const months = [
//   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
//   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
// ];

// const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

// const CustomCalendar = ({ selectedDate = new Date(), onChange, onClose }) => {
//   const [currentDate, setCurrentDate] = useState(new Date(selectedDate));
//   const [showYearList, setShowYearList] = useState(false);
//   const [selectedYear, setSelectedYear] = useState(null);

//   const handleMonthSelect = (monthIndex) => {
//     const newDate = new Date(currentDate);
//     newDate.setMonth(monthIndex);
//     if (selectedYear) newDate.setFullYear(selectedYear);
//     setCurrentDate(newDate);
//     setShowYearList(false);
//     setSelectedYear(null);
//   };

//   const handleYearSelect = (year) => {
//     setSelectedYear(year);
//   };

//   const handleDateSelect = (day, month, year) => {
//     const newDate = new Date(year, month, day);
//     onChange(newDate);
//     onClose();
//   };

//   const toggleYearList = () => {
//     setShowYearList(!showYearList);
//     setSelectedYear(null);
//   };

//   const changeMonth = (delta) => {
//     const newDate = new Date(currentDate);
//     newDate.setMonth(newDate.getMonth() + delta);
//     setCurrentDate(newDate);
//   };

//   const renderYearList = () => {
//     const currentYear = new Date().getFullYear();
//     const years = Array.from({ length: 10 }, (_, i) => currentYear + i - 5);
//     return (
//       <div className="year-list">
//         {years.map(year => (
//           <div key={year}>
//             <div onClick={() => handleYearSelect(year)}>{year}</div>
//             {selectedYear === year && renderMonthView()}
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const renderMonthView = () => (
//     <div className="month-grid">
//       {months.map((month, index) => (
//         <div
//           key={month}
//           className={`month-item ${currentDate.getMonth() === index ? 'selected' : ''}`}
//           onClick={() => handleMonthSelect(index)}
//         >
//           {month}
//         </div>
//       ))}
//     </div>
//   );

//   const renderDayView = () => {
//     const year = currentDate.getFullYear();
//     const month = currentDate.getMonth();
//     const firstDay = new Date(year, month, 1).getDay();
//     const daysInMonth = new Date(year, month + 1, 0).getDate();
//     const daysInPrevMonth = new Date(year, month, 0).getDate();

//     const days = [];

//     // Previous month's days
//     for (let i = firstDay - 1; i >= 0; i--) {
//       const day = daysInPrevMonth - i;
//       days.push(
//         <div
//           key={`prev-${day}`}
//           className="day-item other-month"
//           onClick={() => handleDateSelect(day, month - 1, year)}
//         >
//           {day}
//         </div>
//       );
//     }

//     // Current month's days
//     for (let day = 1; day <= daysInMonth; day++) {
//       days.push(
//         <div
//           key={day}
//           className={`day-item ${selectedDate && selectedDate.getDate() === day && selectedDate.getMonth() === month && selectedDate.getFullYear() === year ? 'selected' : ''}`}
//           onClick={() => handleDateSelect(day, month, year)}
//         >
//           {day}
//         </div>
//       );
//     }

//     // Next month's days
//     const remainingCells = 42 - days.length; // 6 rows * 7 days = 42
//     for (let day = 1; day <= remainingCells; day++) {
//       days.push(
//         <div
//           key={`next-${day}`}
//           className="day-item other-month"
//           onClick={() => handleDateSelect(day, month + 1, year)}
//         >
//           {day}
//         </div>
//       );
//     }

//     return (
//       <>
//         <div className="weekdays">
//           {daysOfWeek.map(day => <div key={day} className="weekday">{day}</div>)}
//         </div>
//         <div className="day-grid">{days}</div>
//       </>
//     );
//   };

//   return (
//     <div className="custom-calendar">
//       <div className="calendar-header">
//         <FiChevronLeft onClick={() => changeMonth(-1)} />
//         <div onClick={toggleYearList}>
//           {months[currentDate.getMonth()]}, {currentDate.getFullYear()}
//           {showYearList ? <FiChevronUp /> : <FiChevronDown />}
//         </div>
//         <FiChevronRight onClick={() => changeMonth(1)} />
//       </div>
//       {showYearList ? renderYearList() : renderDayView()}
//     </div>
//   );
// };

// export default CustomCalendar;

import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp, FiChevronLeft, FiChevronRight } from "react-icons/fi";

const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const CustomCalendar = ({ selectedDate = new Date(), onChange, onClose }) => {
  const [currentDate, setCurrentDate] = useState(new Date(selectedDate));
  const [showYearList, setShowYearList] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const handleMonthSelect = (monthIndex) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(monthIndex);
    if (selectedYear) newDate.setFullYear(selectedYear);
    setCurrentDate(newDate);
    setShowYearList(false);
    setSelectedYear(null);
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  const handleDateSelect = (day, month, year) => {
    const newDate = new Date(year, month, day);
    onChange(newDate);
    onClose();
  };

  const toggleYearList = () => {
    setShowYearList(!showYearList);
    setSelectedYear(null);
  };

  const changeMonth = (delta) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + delta);
    setCurrentDate(newDate);
  };

  const goToToday = () => {
    const today = new Date();
    setCurrentDate(today);
    onChange(today);
  };

  const renderYearList = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear + i - 5);
    return (
      <div className="year-list">
        {years.map(year => (
          <div key={year}>
            <div onClick={() => handleYearSelect(year)}>{year}</div>
            {selectedYear === year && renderMonthView()}
          </div>
        ))}
      </div>
    );
  };

  const renderMonthView = () => (
    <div className="month-grid">
      {months.map((month, index) => (
        <div
          key={month}
          className={`month-item ${currentDate.getMonth() === index ? 'selected' : ''}`}
          onClick={() => handleMonthSelect(index)}
        >
          {month}
        </div>
      ))}
    </div>
  );

  const renderDayView = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInPrevMonth = new Date(year, month, 0).getDate();

    const days = [];

    // Previous month's days
    for (let i = firstDay - 1; i >= 0; i--) {
      const day = daysInPrevMonth - i;
      days.push(
        <div
          key={`prev-${day}`}
          className="day-item other-month"
          onClick={() => handleDateSelect(day, month - 1, year)}
        >
          {day}
        </div>
      );
    }

    // Current month's days
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(
        <div
          key={day}
          className={`day-item ${selectedDate && selectedDate.getDate() === day && selectedDate.getMonth() === month && selectedDate.getFullYear() === year ? 'selected' : ''}`}
          onClick={() => handleDateSelect(day, month, year)}
        >
          {day}
        </div>
      );
    }

    // Next month's days
    const remainingCells = 42 - days.length; // 6 rows * 7 days = 42
    for (let day = 1; day <= remainingCells; day++) {
      days.push(
        <div
          key={`next-${day}`}
          className="day-item other-month"
          onClick={() => handleDateSelect(day, month + 1, year)}
        >
          {day}
        </div>
      );
    }

    return (
      <>
        <div className="weekdays">
          {daysOfWeek.map(day => <div key={day} className="weekday">{day}</div>)}
        </div>
        <div className="day-grid">{days}</div>
        <div className="today-button" onClick={goToToday}>Today</div>
      </>
    );
  };

  
  return (
    <div className="custom-calendar">
      <div className="calendar-header">
        <FiChevronLeft onClick={() => changeMonth(-1)} />
        <div onClick={toggleYearList}>
          {months[currentDate.getMonth()]}, {currentDate.getFullYear()}
          {showYearList ? <FiChevronUp /> : <FiChevronDown />}
        </div>
        <FiChevronRight onClick={() => changeMonth(1)} />
      </div>
      {showYearList ? renderYearList() : renderDayView()}
      
    </div>
  );
};

export default CustomCalendar;